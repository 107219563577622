.ui-state-highlight {
  display: none;
}

.ui-dropdown-items-wrapper {
  min-height: 19em;
  @media (max-width: $tablet-portrait) {
    min-height: 14.3em;
  }
}

// .ui-corner-all {
//   font-size: 0.9em;
//   text-transform: uppercase;
//   padding: 0.3em;
//   @media (max-width: $tablet-portrait) {}
//   font-size: 0.8em;
// }
.ui-dropdown-item {
  font-size: 1em;
}

.ui-state-default {
  background: $white;
}

.ui-dropdown:not(.ui-state-disabled):hover {
  background: $white;
}

.ui-dropdown label.ui-dropdown-label {
  display: flex;
}

.icon-fornitura-filter {
  margin-right: 0.25em;
  font-size: 2.65em !important;
}

/*.ui-corner-all{
    min-width: 11.5em !important;
     @media (max-width: $tablet-portrait){

     }
 }
*/

.filters {
  .ui-dropdown {
    min-width: 20em;
    @media (max-width: $tablet-landscape) {
      min-width: 16em;
    }
    @media (max-width:$tablet-portrait) {
      max-width: 14em;
      min-width: 14em;
    }
    @media (max-width:$smartphone) {
      min-width: 88vw;
      max-width: 95vw;
      margin-top: 1em;
    }
  }
  .ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    background: $gray-200;
  }
}

.ui-calendar.ui-calendar-w-btn input {
  max-width: 8.3em;
  @media (max-width: $tablet-portrait) {
    max-width: 5em;
  }
  @media (max-width:$smartphone) {
    min-width: 17.7em;
    max-width: 17.7em;
  }
}

.ui-button:enabled:hover {
  color: $white;
}

.ui-datepicker-touch-ui,
.ui-datepicker-group .ui-widget-content .ng-tns-c6-2 .ng-star-inserted {
  width: 20vw !important;
  min-width: 20vw !important;
  @media (max-width: $tablet-portrait) {
    width: 30vw !important;
    min-width: 30vw !important;
  }
}

.ui-calendar .ui-datepicker {
  @media (max-width: $smartphone) {
    min-width: 100% !important;
  }
  .ui-datepicker-year {
    width: 50%;
  }
}

.ui-datepicker.ui-datepicker-monthpicker .ui-datepicker-month-cell:hover {
  background: $gray-200;
  text-decoration: none;
  border: none;
}

.ui-button:focus {
  color: $white;
}

.dynamic-form {
  .ui-dropdown {
    width: 100%;
    max-width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .ui-dropdown-label {
      display: block;
      text-align: left;
      font-size: 14px;
      padding-top: 0.438rem;
      padding-bottom: 0.438rem;
    }
    .ui-dropdown-panel .ui-dropdown-item {
      text-align: left;
    }
  }
  /* Dropdown with Tooltip */
  .ui-inputgroup {
    p-dropdown {
      width: 100%;
    }
  }
}
