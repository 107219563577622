@import 'src/theme/colors';
@font-face {
  font-family: 'icomoon';
  src: url('/assets/icon-font/icomoon.eot?58c5rs');
  src: url('/assets/icon-font/icomoon.eot?58c5rs#iefix') format('embedded-opentype'), url('/assets/icon-font/icomoon.ttf?58c5rs') format('truetype'), url('/assets/icon-font/icomoon.woff?58c5rs') format('woff'), url('/assets/icon-font/icomoon.svg?58c5rs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change icon-font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pdf:before {
  content: "\e915";
  color: #4a4a4a;
  font-size: 2.5rem;
}

.icon-locker:before {
  content: "\e911";
  color: #9b9b9b;
  font-size: 2em;
}

.icon-hamburger-menu:before {
  content: "\e90e";
  color: #9b9b9b;
  font-size: 1.6em;
}

.icon-go-to-top-arrow:before {
  content: "\e90d";
}

.icon-fornitura-filter:before {
  content: "\e90b";
  color: #99999a;
}

.icon-edit-icon:before {
  content: "\e908";
  color: #4b4a4b;
}

.icon-download:before {
  content: "\e907";
  color: #4b4a4b;
  font-size: 2rem;
}

.icon-dematerializza:before {
  content: "\e906";
}

.icon-date-filter:before {
  content: "\e905";
}

.icon-autolettura:before {
  content: "\e902";
  color: #4b4a4b;
}

.icon-arrow-white-right:before {
  content: "\e901";
  color: #fff;
}

.icon-arrow-white-left:before {
  content: "\e900";
  color: #fff;
  font-size: 2em;
}

.icon-arrow-small-dark-right:before {
  content: "\e921";
  color: #4b4b4b;
}

.icon-arrow-big-dark-right:before {
  content: "\e91f";
  color: #7b7b7b;
}

.icon-signin:before {
  content: "\e91a";
  color: #9b9b9b;
  font-size: 2em;
}

.icon-search:before {
  content: "\e919";
  color: $green;
}

.icon-placeholder:before {
  content: "\e916";
  color: $white;
  font-size: 1.2em;
}

.icon-teleriscaldamento:before {
  content: "\e90f";
  color: #fff;
  font-size: 2.3rem;
  background: #ea1e2c;
  border-radius: 1em;
}

.icon-gas-icon:before {
  content: "\e90c";
  color: #fff;
  font-size: 2.3rem;
  background: $orange;
  border-radius: 1em;
}

.icon-rifiuti-icon:before {
  content: "\e918";
  color: #fff;
  font-size: 2.3rem;
  background: $green;
  border-radius: 1em;
}

.icon-acqua-icon:before {
  content: "\e91e";
  color: #fff;
  font-size: 2.3rem;
  background: $blue;
  border-radius: 1em;
}

.icon-luce-icon:before {
  content: "\e913";
  color: #fff;
  font-size: 2.3rem;
  background: $yellow;
  border-radius: 1em;
}

.icon-arrow-small-dark-down:before {
  content: "\e920";
  font-size: 1em;
}

.icon-logout:before {
  content: "\e912";
  color: #9b9b9b;
  font-size: 1.7em;
}

.icon-payment:before {
  content: "\e914";
  color: #4b4a4b;
  font-size: 2.2rem;
}

.icon-cards:before {
  content: "\e904";
  color: #99999a;
}

.icon-list:before {
  content: "\e910";
  color: #99999a;
}

.icon-excel:before {
  content: "\e90a";
  color: #4a4a4a;
}

.icon-zip:before {
  content: "\e91d";
  color: #4a4a4a;
}

.icon-edit-pencil:before {
  content: "\e909";
  color: $white;
}

.icon-balloon:before {
  content: "\e903";
  color: $white;
  font-size: 2rem;
}

.icon-request:before {
  content: "\e917";
}

.icon-vuotatura:before {
  content: "\e91c";
}

.icon-upload:before {
  content: "\e91b";
  color: #4b4a4b;
  font-size: 2rem;
}

.icon-instagram:before {
  content: "\e922";
  color: #fff;
}

.icon-google-plus:before {
  content: "\e923";
  color: #fff;
}

.icon-facebook:before {
  content: "\e924";
  color: #fff;
}
