@import '../primeng-variables';
.ui-messages {
  padding: 1em;
  margin: 1em 0;
  &.ui-messages-info {
    background-color: $infoMessageBgColor;
    border: $infoMessageBorder;
    color: $infoMessageTextColor;
    &.ui-messages-icon {
      color: $infoMessageIconColor;
    }
  }
  &.ui-messages-success {
    background-color: $successMessageBgColor;
    border: $successMessageBorder;
    color: $successMessageTextColor;
    &.ui-messages-icon {
      color: $successMessageIconColor;
    }
  }
  &.ui-messages-warn {
    background-color: $warnMessageBgColor;
    border: $warnMessageBorder;
    color: $warnMessageTextColor;
    &.ui-messages-icon {
      color: $warnMessageIconColor;
    }
  }
  &.ui-messages-error {
    background-color: $errorMessageBgColor;
    border: $errorMessageBorder;
    color: $errorMessageTextColor;
    &.ui-messages-icon {
      color: $errorMessageIconColor;
    }
  }
}

.ui-toast .ui-toast-message {
  // -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba($black, 0.16);
  margin: 0 0 1em 0;
  &.ui-toast-message-info {
    background-color: $infoMessageBgColor;
    border: $infoMessageBorder;
    color: $infoMessageTextColor;
    &.ui-toast-close-icon {
      color: $infoMessageIconColor;
    }
  }
  &.ui-toast-message-success {
    background-color: $successMessageBgColor;
    border: $successMessageBorder;
    color: $successMessageTextColor;
    &.ui-toast-close-icon {
      color: $successMessageIconColor;
    }
  }
  &.ui-toast-message-warn {
    background-color: $warnMessageBgColor;
    border: $warnMessageBorder;
    color: $warnMessageTextColor;
    &.ui-toast-close-icon {
      color: $warnMessageIconColor;
    }
  }
  &.ui-toast-message-error {
    background-color: $errorMessageBgColor;
    border: $errorMessageBorder;
    color: $errorMessageTextColor;
    &.ui-toast-close-icon {
      color: $errorMessageIconColor;
    }
  }
}
