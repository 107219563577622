.dynamic-form {
  .ui-calendar {
    width: 100%;
    text-align: left;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .ui-inputtext {
      width: calc(100% - 2em);
      max-width: 100%;
      border-left: 0;
      border-top: 0;
      border-bottom: 0;
      border-right: 1px solid #ced4da;
      text-align: left;
    }
    .ui-datepicker-trigger {
      border: none;
    }
  }
  /* Calendar with Tooltip */
  .ui-inputgroup {
    p-calendar {
      width: 100%;
    }
  }
}
