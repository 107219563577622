/* You can add global styles to this file, and also import other style files */

@import './theme/colors';
@import './theme/theme';
@import 'src/theme/variables';
body {
  position: relative;
  min-height: 100vh;
}

.main-container {
  min-height: $main-container-height;
}

.box-shadow-gray {
  box-shadow: 1px 1px 12px 1px $gray-500;
}

.ng-dirty.ng-invalid {
  border-color: $danger;
}

.invalid-field {
  color: $danger;
}

.text-shadow-none {
  text-shadow: none;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-50 {
  opacity: 0.5;
}

.smartphone-d-none {
  @media (max-width: $smartphone) {
    display: none !important;
  }
}

.only-smartphone {
  @media (min-width: $smartphone) {
    display: none !important;
  }
}

/*BACKGROUOD COLORS*/

.background-gray-50 {
  background: rgba($gray-200, 0.7);
}

.background-light-azure {
  background: $light-azure;
}

.background-orange {
  background: $orange;
}

.background-yellow {
  background: $yellow;
}

.background-gray-200 {
  background: $gray-200;
}

.background-gray-300 {
  background: $gray-300;
}

.bg-gray-600 {
  background: $gray-600;
}

.bg-power {
  background: $yellow;
}

.bg-power-50 {
  background: rgba($yellow, 0.6);
}

.bg-waste {
  background: $green;
}

.bg-gas {
  background: $orange;
}

.bg-gas-50 {
  background: rgba($orange, 0.7);
}

.bg-water {
  background: $dark-azure;
}

.bg-blue {
  background: $blue;
}

.bg-district-heating {
  background: $red;
}

.bg-district-heating-50 {
  background: rgba($red, 0.6);
}

/*COLORS*/

.text-dark-azure {
  color: $dark-azure;
}

.text-gas {
  color: $orange;
}

.text-water {
  color: $blue;
}

.text-power {
  color: $yellow;
}

.text-waste {
  color: $green;
}

.text-district-heating {
  color: $red;
  font-size: $font-size-sm;
}

/*ICONS*/

.gas-e-luce-icon {
  width: 2.4em;
}

// .ui-dialog {
//   z-index: 1000 !important;
// }
// .ui-confirmdialog {
//   z-index: 1006 !important;
// }
/*RESPONSIVE*/

.page-title {
  @media (max-width: $smartphone) {
    text-align: center;
  }
}

/*FONT*/

h1 {
  @media (max-width: $smartphone) {
    font-size: 2em;
  }
}

h3 {
  @media (max-width: $smartphone) {
    font-size: 1.5em;
  }
}

h4 {
  @media (max-width: $smartphone) {
    font-size: 1.3em;
  }
}
