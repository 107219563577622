@import '../colors';
.ui-accordion-header {
  background-color: $gray-300;
  a {
    color: $green;
    padding: 1em 1em !important;
    text-decoration: none;
  }
  span:first-child {
    transform: rotate(90deg);
    float: right;
  }
  span {
    font-size: 1.2em;
  }
}

.ui-accordion-header:hover {
  background: $gray-400 !important;
}
