.dynamic-form {
  p-radiobutton {
    .ui-radiobutton-label {
      margin-bottom: 0;
    }
  }
  /* Radio with Tooltip */
  .ui-inputgroup-radio {
    display: inline-block;
    p-radiobutton {
      .ui-radiobutton {
        vertical-align: middle;
      }
      .ui-radiobutton-label {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
